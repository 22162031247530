/* Styles for the Courses component */



.height-75 {
    background-image: url('/src/utils/images/education-course.jpg'); /* Replace with your header background image path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 75vh; /* Adjust as needed */
}

.text-light {
    color: #f8f9fa; /* Adjust as needed to ensure readability */
}

.card {
    background-color: rgba(0, 0, 0, 0.5); /* Slightly transparent background for cards */
}

.courses-header-title {
    color: #FB7240; /* Title color */
    font-weight: bold;
}

.courses-description {
    color: #f8f9fa; /* Adjust description color if needed */
    margin-left: 20%;
}

.courses-back2home {
    font-weight: bold;
    color: red;
    text-decoration: underline;
    font-style: italic;
    margin-left: 20%;
}
